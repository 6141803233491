<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','class_rebate']"
            ref="list"
        >
            <template slot="operation" slot-scope="data">
                <a @click="details(data.record)">查看合约</a>
                <a-divider type="vertical" v-if='data.record.approval_id' />
               <a v-if='data.record.approval_id' @click="handle_details_click(data.record)">查看审批</a>
            </template>
        </TableList>
		<Details ref="details"></Details>
    </div>
</template>

<script>
// @ is an alias to /src
import Details from "@/views/freezer/approval/details";

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getContractList,getContractInfo,getStatusSelect} from "@/api/freezer";
import { getType } from '@/api/approval'
let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "合约名称",
        dataIndex: "title",
    },
	{
	    title: "合约期限",
	    dataIndex: "term",
	},
	{
	    title: "资产编号",
	    dataIndex: "goods_code",
	},
    {
        title: "状态",
        dataIndex: "status_name",
    },
    {
        title: "签约业代所属部门",
        dataIndex: "path_name",
    },
    {
        title: "客户名称",
        dataIndex: "customer_name",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,Details
    },
    data() {
        return {
            get_table_list: getContractList,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "签约业代所属部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "合约名称",
                        placeholder: '请输入合约名称',
                        options: {

                        }
                    },
                    {
                        type: "text",
                        name: "customer",
                        title: "客户名称",
                        placeholder: '请输入客户名称、客户ID',
                        options: {

                        }
                    },
					{
					    type: "text",
					    name: "goods_code",
					    title: "资产编号",
					    placeholder: '请输入资产编号',
					    options: {
					
					    }
					},
                    {
                        type: 'select',
                        name: 'status',
                        title: '状态',
                        list: [],
                        options: {},
                    },
                    
                    
                  
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
		// 缓存该页面
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
		getStatusSelect().then(res=>{
			let list = res.data.list
			let arr=[]
			list.forEach(item=>{
				arr.push({value:item.label,key:item.value})
			})
			this.form_data_seo.list[3].list = arr
		})
    },
    methods: {
		handle_details_click(record) {
			this.$refs.details.get_details(record);
		},
        details(data) {
			this.$keep_route.add(this.$options.name);
            this.$router.push('contract_detail?id=' + data.id)
        },
        del(data) {
            delContract({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
		/* report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_cost_contract",
					...this.$refs.list.seo_data
				}
			});
		}, */
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>